function bp(data) {
  // set the dimensions and margins of the graph
  var margin = { top: 10, right: 30, bottom: 30, left: 55 },
    width = 1024 - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom;

  // append the svg object to the body of the page
  var svg = d3.select("#my_dataviz")
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform",
      "translate(" + margin.left + "," + margin.top + ")");

  // Read the data and compute summary statistics for each specie
  // d3.csv("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/iris.csv", function (data) {

  // Compute quartiles, median, inter quantile range min and max --> these info are then used to draw the box.
  // debugger
  var sumstat = d3.nest() // nest function allows to group the calculation per level of a factor
    .key(function (d) { return d.modelName; })
    .rollup(function (d) {
      q1 = d3.quantile(d.map(function (g) { return g.maxSalesValue; }).sort(d3.ascending), .25)
      median = d3.quantile(d.map(function (g) { return g.maxSalesValue; }).sort(d3.ascending), .5)
      q3 = d3.quantile(d.map(function (g) { return g.maxSalesValue; }).sort(d3.ascending), .75)
      interQuantileRange = q3 - q1
      min = d3.min(d.map(function (g) { return g.maxSalesValue; }))
      max = d3.max(d.map(function (g) { return g.maxSalesValue; }))
      return ({ q1: q1, median: median, q3: q3, interQuantileRange: interQuantileRange, min: min, max: max })
    })
    .entries(data)

  // Show the X scale
  const modelList = [...new Set(data.map(x => x.modelName))];

  var x = d3.scaleBand()
    .range([0, width])
    .domain(modelList)
    .paddingInner(1)
    .paddingOuter(.5)
  svg.append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(x))

  // Show the Y scale
  const saleValueList = [...new Set(data.map(x => x.maxSalesValue))];
  const maxSaleValue = Math.max(...saleValueList);

  var y = d3.scaleLinear()
    .domain([0, maxSaleValue])
    .range([height, 0])
  svg.append("g").call(d3.axisLeft(y))

  // Show the main vertical line

  svg
    .selectAll("vertLines")
    .data(sumstat)
    .enter()
    .append("line")
    .attr("x1", function (d) { return (x(d.key)) })
    .attr("x2", function (d) { return (x(d.key)) })
    .attr("y1", function (d) { return (y(d.value.min)) })
    .attr("y2", function (d) { return (y(d.value.max)) })
    .attr("stroke", "black")
    .style("width", 40)

  svg
    .selectAll(null)
    .data(sumstat)
    .enter()
    .append("text")
    .attr("x", function (d) { return (x(d.key)) + 6 })
    .attr("y", function (d) { return (y(d.value.min)) })
    .attr("font-size", "10")
    .text((d) => {
      
        if(d.value.min !=d.value.max)
        {
        return getCurrencyFormat(d.value.min); 
        }
        else{
        return ""; 
        }
      
    })

  svg
    .selectAll(null)
    .data(sumstat)
    .enter()
    .append("text")
    .attr("x", function (d) { return (x(d.key)) + 6 })
    .attr("y", function (d) { return (y(d.value.max)) })
    .attr("font-size", "10")
    .text((d) =>{
      if(d.value.min !=d.value.max)
      {
      return getCurrencyFormat(d.value.max); 
      }
      else{
      return ""; 
      }
     })


  // rectangle for the main box
  var boxWidth = 100
  svg
    .selectAll("boxes")
    .data(sumstat)
    .enter()
    .append("rect")
    .attr("x", function (d) { return (x(d.key) - boxWidth / 2) })
    .attr("y", function (d) { return (y(d.value.q3)) })
    .attr("height", function (d) { return (y(d.value.q1) - y(d.value.q3)) })
    .attr("width", boxWidth)
    .attr("stroke", "black")
    .style("fill", "#69b3a2")

  svg
    .selectAll(null)
    .data(sumstat)
    .enter()
    .append("text")
    .attr("x", function (d) { return (x(d.key) + boxWidth / 2) + 6 })
    .attr("y", function (d) { return (y(d.value.q1)) })
    .attr("font-size", "10")
    .text((d) => {
      if(d.value.q1 !=d.value.q3)
      {
      return getCurrencyFormat(d.value.q1); 
      }
      else{
      return ""; 
      }
    })

  svg
    .selectAll(null)
    .data(sumstat)
    .enter()
    .append("text")
    .attr("x", function (d) { return (x(d.key) + boxWidth / 2) + 6 })
    .attr("y", function (d) { return (y(d.value.q3)) })
    .attr("font-size", "10")
    .text((d) => {
      if(d.value.q1 !=d.value.q3)
      {
      return getCurrencyFormat(d.value.q3); 
      }
      else
     {return ""; 
    }
    })


  // Show the median
  svg
    .selectAll("medianLines")
    .data(sumstat)
    .enter()
    .append("line")
    .attr("x1", function (d) { return (x(d.key) - boxWidth / 2) })
    .attr("x2", function (d) { return (x(d.key) + boxWidth / 2) })
    .attr("y1", function (d) { return (y(d.value.median)) })
    .attr("y2", function (d) { return (y(d.value.median)) })
    .attr("stroke", "black")
    .style("width", 80)
  // })

  svg.selectAll(null)
    .data(sumstat)
    .enter()
    .append("text")
    .attr("x", function (d) { return (x(d.key) + boxWidth / 2) + 6 })
    .attr("y", function (d) { return (y(d.value.median) + 4) })
    .attr("font-size", "12")
    .attr("stroke", "brown")
    .text((d) => getCurrencyFormat(d.value.median))
    return sumstat;
}
function getCurrencyFormat(Currency){
    return Currency.toLocaleString('en-IN', {
       maximumFractionDigits: 2,
       style: 'currency',
       currency: 'INR'
   });
  
}